import uuid from 'uuid/v4';

export default {
    namespaced: true,
    state () {
        return {
            messages: [],
        };
    },
    getters: {
        messages (state)
        {
            return state.messages;
        }
    },
    mutations: {
        addMessage (state, message)
        {
            state.messages.push(message);
        },
        removeMessage (state, id)
        {
            console.log('remove message', id);
            state.messages = state.messages.filter(message => message.id != id);
        }
    },
    actions: {
        flash ({ commit }, message)
        {
            const newMessage = {
                id: uuid(),
                title: message.title ? message.title : null,
                text: message.text ? message.text : null,
                for: message.for ? message.for : 5,
                timestamp: Date.now(),
            };

            commit('addMessage', newMessage);
            window.setTimeout(() => {
                commit('removeMessage', newMessage.id);
            }, newMessage.for * 1000);
        },
    },
};