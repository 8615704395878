<template>
    <div>
    <textarea @focus.prevent="open" rows="7" class="form-control" style="background-size: contain; background-repeat: no-repeat;" :style="{'background-image': `url(${modelValue})`}"></textarea>
    <div :class="{ 'visually-hidden': !visible }" class="bg-info" style="position: fixed; top: 0; left: 0; z-index: 9999;">
        <signature-pad @input="onInput" ref="pad" :width="width" :height="height" />
        <div class="fixed-bottom d-flex align-items-center p-1">
            <button @click.prevent="close" class="btn btn-secondary"><i class="bi bi-x"></i></button>
            <button @click.prevent="clearSignature" type="button" class="btn btn-secondary">Clear</button>
        </div>
    </div>
    </div>
</template>

<script>
import SignaturePad from 'vue3-signature-pad';
import { useWindowSize } from 'vue-window-size';
import { ref } from '@vue/reactivity';

export default {
    components: {
        SignaturePad,
    },
    props: {
        modelValue: {
            required: false,
        }
    },
    setup(props, { emit }) {
        const { width, height } = useWindowSize();
        const visible = ref(false);
        const pad = ref(null);
        function padComponent()
        {
            if (!pad.value) {
                throw new Error('No signature pad ref could be found!');
            } else {
                return pad.value;
            }
        }

        function clearSignature()
        {
            padComponent().clearSignature();
        }

        function saveSignature()
        {
            const signature = padComponent().saveSignature();
            return signature;
        }

        function open()
        {
            visible.value = true;
        }

        function close()
        {
            visible.value = false;
            saveSignature();
        }

        function onInput(event)
        {
            emit('update:modelValue', event);
        }

        return {
            width,
            height,
            visible,
            open,
            close,
            clearSignature,
            pad,
            saveSignature,
            onInput,
        };
    },
}
</script>