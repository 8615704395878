import useApi from './index';

export default function () {
    const { client } = useApi();
    const resourcePath = '/customer';
    
    function search(searchterm) {
        return client.get(`${resourcePath}/search/${searchterm}`);
    }

    return {
        search   
    };
}