
import useAuthApi from '../../../api/auth';
export default {
    state: () => ({
        token: null,
    }),
    getters: {
        token(state) {
            return state.token;
        },
        isLoggedIn(state) {
            return state.token !== null;
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
    },
    actions: {
        login({ commit }, { username, password }) {
            const { login } = useAuthApi();
            return login(username, password).then(res => {
                return commit('setToken', res.data.token);
            })
        },
        logout({ commit }) {
            commit('setToken', null)
        },

    }
}