import axios from 'axios';
import { useStore } from 'vuex'

export default function () {
    const { getters } = useStore()
    const token = getters.token;
  
    const baseURL = process.env.VUE_APP_API_URL;
    const client = axios.create({
        baseURL: baseURL,
        headers: {'Authorization': `Bearer ${token}` }
      });
      
    return {
        client,
    };
}