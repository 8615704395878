<template>
    <div class="list-group my-3">
        <div :key="index" v-for="(item, index) in items" class="list-group-item d-flex">
            <div style="width: 100%;">
                <div class="row">
                    <div class="mb-3 col-8">
                        <label for="bouquet-product-select" class="form-label">Produkt</label>
                        <select class="form-select" id="bouquet-product-select" aria-label="Default select example"
                            @change="updatePrice(index)" v-model="items[index].article">
                            <option :value="article._id" :key="article._id" v-for="article in articles">{{
                                    article.LANGNAME
                            }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3 col-2" v-if="items[index].final_price">
                        <label for="bouquet-form-value-input" class="form-label">Preis</label>
                        <input v-model="items[index].value" type="number" class="form-control"
                            id="bouquet-form-value-input" placeholder="123" required />
                    </div>
                    <div class="mb-3 col-1" v-if="!items[index].final_price">
                        <label for="bouquet-form-value-input" class="form-label">Von</label>
                        <input v-model="items[index].from" type="number" class="form-control"
                            id="bouquet-form-value-input" placeholder="123" required />
                    </div>
                    <div class="mb-3 col-1" v-if="!items[index].final_price">
                        <label for="bouquet-form-value-input" class="form-label">Bis</label>
                        <input v-model="items[index].to" type="number" class="form-control"
                            id="bouquet-form-value-input" placeholder="123" required />
                    </div>
                    <div class="mb-3 col-2 d-flex align-items-center">
                        <input type="checkbox" id="bouquet-form-final-price" class="form-check-input me-2"
                            v-model="items[index].final_price" />
                        <label for="bouquet-form-final-price" class="form-check-label">Finaler Preis?</label>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="bouquet-form-notes-input" class="form-label">Bemerkungen</label>
                    <textarea v-model="items[index].notes" rows="5" class="form-control" id="bouquet-form-notes-input"
                        placeholder="Bemerkungen"></textarea>
                </div>
                <div class="mb-3">
                    <label class="form-label">Zeichnung</label>
                    <signature-input v-model="items[index].sketch" />
                </div>
            </div>
            <div class="d-flex align-items-end pb-3 ms-3">
                <button @click.prevent="removeItem(index)" v-if="items.length > 1" class="btn btn-outline-danger">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
        </div>
        <button @click.prevent="addItem" class="list-group-item list-group-item-action">
            <i class="bi bi-plus"></i> Posten hinzufügen
        </button>
    </div>
</template>
<script>
import { computed } from '@vue/reactivity';
import SignatureInput from '../../SignatureInput.vue';

export default {
    components : {
        SignatureInput
    },
    props: { formItems: Array, articles : Array },
    setup(props, {emit}) {
        const items = computed({
            get: () => props.formItems,
            set: (val) => emit("update:items", val),
        });
        function addItem() {
            items.value.push({
                item: null,
                notes: '',
                final_price: false,
                from: null,
                to: null,
                value: null,
                sketch: null,
            });
        }
        function removeItem(index) {
            if (items.value.length > 1) {
                items.value.splice(index, 1);
            }
        }

        function updatePrice(itemIndex) {
            if (!items[itemIndex].value) {
                var article = props.articles.value.find(
                    article => article._id == items[itemIndex].article
                );
                items[itemIndex].value = parseFloat(article.VK1.$numberDecimal);
            }
        }

        return {
            addItem,
            removeItem,
            updatePrice,
            items
        }
    },
}
</script>
