<template>
  <div>
    <div class="alert alert-success" role="alert" v-if="isSaveSucess">
      Auftrag wurde erfolgreich gespeichert
    </div>

    <div class="mb-3">
      <label for="formTypeSelect" class="form-label">Auftragstyp</label>
      <select
        class="form-select"
        id="formTypeSelect"
        v-model="formType"
        :disabled="isChange"
        aria-label="Default select example"
      >
        <option value="bouquet">Standard</option>
        <option value="grave">Trauer</option>
      </select>
    </div>
    <grave-form
      v-if="formType == 'grave'"
      :order="order"
      :isChange="isChange"
      @save="isSaveSucess = true"
    />
    <bouquet-form
      v-else-if="formType == 'bouquet'"
      :order="order"
      :isChange="isChange"
      @save="isSaveSucess = true"
    />
  </div>
</template>
<script>
import BouquetForm from '../components/forms/Bouquet.vue'
import GraveForm from '../components/forms/Grave.vue'
import useOrdersApi from '../api/orders'

import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
export default {
  components: {
    BouquetForm,
    GraveForm,
  },
  setup() {
    const { show } = useOrdersApi()
    const route = useRoute()
    const formType = ref('bouquet')
    const isSaveSucess = ref(false)
    const order = ref(null)
    const isChange = ref(false)
    
    onMounted(() => {
      const id = route.params.id
      if (id) {
        show(id).then(
          (res) => (
            isChange.value = true,
            (order.value = res.data),
            (formType.value = res.data.type)
          )
        )
      }
    })

    return {
      formType,
      isSaveSucess,
      isChange,
      order,
    }
  },
}
</script>
