<template>
    <div class="input-group mb-3">
        <select
            :value="modelValue"
            :disabled="displayCustomTextInput"
            @input="emitChanges"
            class="form-select"
        >
            <option value="" selected>Textbausteine</option>
            <template :key="index" v-for="(textElement, index) in textElements">
                <option :value="textElement">{{ textElement }}</option>
            </template>
        </select>
        <label class="input-group-text">
            <i class="bi bi-pencil me-1"></i>
            <input v-model="displayCustomTextInput" class="form-check-input mt-0" type="checkbox" value="" aria-label="Eigenen Text eingeben">
        </label>
    </div>
    <template v-if="displayCustomTextInput">
        <textarea
            :value="modelValue"
            @input="emitChanges"
            rows="5"
            class="form-control"
            id="grave-form-text-left-input"
            placeholder="Schleifentext links"
        ></textarea>
    </template>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const textElements = [
      "In stillem Gedenken",
      "Als letzten Gruß",
      "Ruhe sanft",
      "Ruhe in Frieden",
      "In liebevoller Erinnerung",
      "In Liebe und Dankbarkeit",
      "Du wirst immer in meinem Herzen sein",
      "Du wirst immer in unseren Herzen sein",
      "Wir werden Dich immer in unserer Erinnerung behalten",
      "… und immer sind da Spuren, Bilder und Gedanken die dich unvergessen machen"
    ];

    const displayCustomTextInput = ref(false);
    if (props.modelValue && !textElements.includes(props.modelValue)) {
      displayCustomTextInput.value = true;
    }

    function emitChanges(event) {
      emit("update:modelValue", event.target.value);
    }

    return {
      textElements,
      displayCustomTextInput,
      emitChanges
    };
  }
};
</script>
