<template>
    <span>{{ typeDisplay }}</span>
</template>

<script>
import { computed } from '@vue/reactivity';

const typeMap = {
    grave: 'Trauer',
    bouquet: 'Standard',
};

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const typeDisplay = computed(() => typeMap[props.type]);

        return {
            typeDisplay,
        };
    },
}
</script>