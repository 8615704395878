<template>
    <span>{{ typeDisplay }}</span>
</template>

<script>
import { computed } from '@vue/reactivity';

const typeMap = {
    open: 'Offen',
    finish: 'Abgeschlossen',
    done: 'Bearbeitet',
    callback: 'Rückruf'
};

export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const typeDisplay = computed(() => typeMap[props.status]);

        return {
            typeDisplay,
        };
    },
}
</script>