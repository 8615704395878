<template>
  <Header />
  <div class="container py-5">
    <slot></slot>
  </div>
  <Footer />
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
