import useApi from './index';

export default function () {
    const { client } = useApi();
    const resourcePath = '/articles';
    
    function list(data = null) {
        return client.get(resourcePath, { params: data });
    }

    return {
        list   
    };
}