<template>
  <nav
    class="navbar navbar-expand-lg navbar-light d-print-none"
    style="background-color: #1a6541;"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="#"
        ><img
          src="../../assets/logo.png"
          height="100"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="!isLoggedIn">
            <a class="nav-link text-white fs-4 active" aria-current="page" href="/login">Login</a>
          </li>

          <li class="nav-item" v-if="isLoggedIn">
            <a class="nav-link text-white fs-4 active" aria-current="page" href="/">Übersicht</a>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <a class="nav-link text-white fs-4" href="/create">Anlegen</a>
          </li>
        </ul>
      </div>
      <div>
        <current-staff-picker v-if="isLoggedIn" />
      </div>
    </div>
  </nav>
  <div>
    <template :key="message.id" v-for="message in messages">
      <div class="alert alert-info">
        <span>{{ message.text }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex';
import CurrentStaffPicker from '../../components/layout/navbar/CurrentStaffPicker.vue';
export default {
    components: {
        CurrentStaffPicker,
    },
    setup() {
      const { getters, state } = useStore()
      const isLoggedIn = computed(() => getters.isLoggedIn);
      const messages = computed(() => state.messages.messages);
      return {
        isLoggedIn,
        messages,
    };
    }
}
</script>
