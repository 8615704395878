<template>
  <form @submit.prevent="submit" class="needs-validation">
    <div class="card card-body">
      <select-customer v-model:customer="form.customer" 
        v-if="form.customer" />
    </div>
    <order-items v-model:formItems="form.items" :articles="articles" />
    <div class="card card-body">
      <recipient-input v-model="form.recipient" />
      <div class="row">
        <div class="mb-3 col-3 d-flex flex-column justify-content-around align-items-center">
          <div class="form-check">
            <input v-model="form.delivery" :value="true" class="form-check-input" type="radio"
              id="bouquet-form-delivery-yes-radio" />
            <label class="form-check-label" for="bouquet-form-delivery-yes-radio">
              Zustellung
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.delivery" :value="false" class="form-check-input" type="radio"
              id="bouquet-form-delivery-no-radio" />
            <label class="form-check-label" for="bouquet-form-delivery-no-radio">
              Abholung
            </label>
          </div>
        </div>
        <div class="mb-3 col-3 d-flex flex-column justify-content-around align-items-center">
          <div class="form-check">
            <input v-model="form.payment" value="cash" class="form-check-input" type="radio"
              id="bouquet-payment-cash" />
            <label class="form-check-label" for="bouquet-payment-cash">
              Barzahlung
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.payment" value="pickup" class="form-check-input" type="radio"
              id="bouquet-payment-pickup" />
            <label class="form-check-label" for="bouquet-payment-pickup">
              Bereits Bezhalt
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.payment" value="invoice" class="form-check-input" type="radio"
              id="bouquet-payment-invoice" />
            <label class="form-check-label" for="bouquet-payment-invoice">
              Rechnung
            </label>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="bouquet-form-day-of-week-input" class="form-label">Datum</label>
          <Datepicker v-model="form.date" locale="de"  :minDate="new Date()"  cancelText="abbrechen" selectText="auswählen"
            format="dd.MM.yyyy HH:mm" />
        </div>
      </div>

    </div>
    <div class="card my-3">
      <div class="card-body">
        <label class="form-label">Unterschrift</label>
        <signature-input v-model="form.signature" />
      </div>
      <div class="card-footer d-flex align-items-center justify-content-between">
        Unterschrift
        <!--<button class="btn btn-sm btn-secondary">Zurücksetzen</button>-->
      </div>
    </div>
    <button class="btn btn-secondary" type="submit">Speichern</button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('open')">
      Offen
    </button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('done')">
      Bearbeitet
    </button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('callback')">
      Rückruf
    </button>
    <button class="btn btn-primary btn-success ms-3" v-if="isChange" @click="setStatusFinish()">
      Abschluss
    </button>

  </form>
</template>

<script>
import { reactive, ref, onMounted, computed, watch } from 'vue';
import SelectCustomer from './SelectCustomer';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { useStore } from 'vuex';
import SignatureInput from '../SignatureInput.vue';
import useArticlesApi from '../../api/articles'
import useOrdersApi from '../../api/orders'
import { useRouter } from 'vue-router';
import RecipientInput from './partials/RecipientInput.vue';
import OrderItems from './partials/OrderItems.vue';

export default {
  components: {
    SelectCustomer,
    Datepicker,
    SignatureInput,
    RecipientInput,
    OrderItems
  },
  props: ["order", "isChange"],
  emit: ["save"],
  setup(props, { emit }) {
    const { list: loadAll } = useArticlesApi();
    const { store } = useOrdersApi();
    const router = useRouter();
    const articles = ref([]);
    onMounted(() => {
      loadAll().then(res => (articles.value = res.data.articles));
    });

    const vuex = useStore();

    const initialForm = {
      customer: {
        name: "",
        address: "",
        location: "",
        phone: "",
        email: ""
      },
      payment: "cash",
      status: "open",
      notes: "",
      value: "",
      items: [],
      article: null,
      date: new Date(),
      delivery: false,
      recipient: {
        name: "",
        address: "",
        location: ""
      },
      signature: '',
    }

    function addItem() {
      form.items.push({
        item: null,
        notes: '',
        final_price: false,
        from: null,
        to: null,
        value: null,
        sketch: null,
      });
    }

    function removeItem(index) {
      if (form.items.length > 1) {
        form.items.splice(index, 1);
      }
    }

    var form = null;
    console.log(props);
    if (!props.order) {
      form = reactive({
        ...initialForm,
        recipient: { ...initialForm.recipient }
      });
    }

    watch(
      () => props.order,
      order => {
        console.log(form);
        Object.assign(form, {
          _id: order._id,
          ...order,
          items: [...order.items.map(item => {
            return { ...item, article: item.article?._id };
          })],
          customer: { ...order.customer },
          recipient: { ...order.recipient }
        });
        console.log(form);
      }
    );

    const { getters } = useStore();
    const staff = computed(() => getters.active);

    function submit() {
      calculateTotalValue();
      store({ ...form, type: "bouquet", staff: staff.value }).then(() => {
        Object.assign(form, initialForm);
        emit("save");
        vuex.dispatch("messages/flash", {
          text: "Auftrag wurde erfolgreich gespeichert"
        });
        router.push({ name: "overview" });
      });
    }

    function setStatus(status) {
      form.status = status;
      submit();
    }

    function updatePrice(itemIndex) {
      if (!form.items[itemIndex].value) {
        var article = articles.value.find(
          article => article._id == form.items[itemIndex].article
        );
        form.items[itemIndex].value = parseFloat(article.VK1.$numberDecimal);
      }
    }

    function calculateTotalValue() {
      let totalValue = 0;
      form.items.forEach(item => {
        totalValue += item.value;
      });
      form.value = totalValue;
    }


    const setStatusFinish = (e) => {
      if (form.items.some(item => !item.final_price)) {
        e.preventDefault();
        alert("Bitte geben Sie einen finalen Preis ein.")
      } else {
        setStatus('finish');
      }
    }


    return {
      form,
      submit,
      articles,
      addItem,
      removeItem,
      updatePrice,
      setStatus,
      emit,
      setStatusFinish
    };
  }
};
</script>
