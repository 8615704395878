<template>
    <select
        @input.prevent="setActive($event.target.value)"
        class="form-select"
        aria-label="Default select example"
    >
        <template :key="staff._id" v-for="staff in staffList">
            <option
                :value="staff._id"
                :selected="staff._id == active"
            >
                {{ staff.name }}
            </option>
        </template>
    </select>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import useStaffApi from '../../../api/staff';
import { onMounted } from '@vue/runtime-core';
export default {
    setup () {
        const { index: loadAll } = useStaffApi();
        const { getters, commit } = useStore();

        const active = computed(() => getters.active);
        const hasActive = computed(() => getters.hasActive);

        const staffList = ref([]);

        function setActive (staff) {
            commit('setActive', staff);
        }

        function loadStaff () {
            loadAll().then((response) => {
                staffList.value = response.data;
            });
        }

        onMounted(() => {
            loadStaff();
        });

        return {
            active,
            hasActive,
            setActive,
            staffList,
        };
    },
}
</script>