<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <span class="mx-1">Zeitraum</span>
      <datepicker
        v-model="after"
        :autoApply="true"
        :closeOnAutoApply="true"
        :enableTimePicker="false"
        locale="de"
        cancelText="abbrechen"
        selectText="auswählen"
        format="dd.MM.yyyy"
      />
      <i class="bi bi-arrow-right mx-1"></i>
      <datepicker
        v-model="before"
        :autoApply="true"
        :closeOnAutoApply="true"
        :enableTimePicker="false"
        locale="de"
        cancelText="abbrechen"
        selectText="auswählen"
        format="dd.MM.yyyy"
      />
      <select class="form-select mx-2"  v-model="status" style="width: auto;">
        <option value="">Alle</option>
        <option value="open">Offen</option>
        <option value="done">Bearbeitet</option>
        <option value="finish">Abgeschlossen</option>
        <option value="callback">Rückruf</option>
      </select>
      <button @click.prevent="loadOrder" class="btn btn-primary me-1">
        Anwenden
      </button>
      <button
        @click.prevent="selectCurrentMonth"
        class="btn btn-secondary mx-1"
      >
        Aktueller Monat
      </button>
    </div>
    <div class="d-flex align-items-center">
      <span class="mx-1">Einträge pro Seite</span>
      <select v-model="limit" @change="loadOrder" class="form-control">
        <template :key="option" v-for="option in [10, 20, 50]">
          <option :value="option">{{ option }}</option>
        </template>
      </select>
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" role="button" @click.prevent="updateOrder('customer')">
          Kunde
          <template v-if="orderBy === 'customer'">
            <order-direction-indicator type="text" :asc="orderAsc" />
          </template>
        </th>
        <th scope="col" role="button" @click.prevent="updateOrder('type')">
          Auftragstyp
          <template v-if="orderBy === 'type'">
            <order-direction-indicator :asc="orderAsc" />
          </template>
        </th>
        <th scope="col" role="button" @click.prevent="updateOrder('status')">
          Status
          <template v-if="orderBy === 'status'">
            <order-direction-indicator :asc="orderAsc" />
          </template>
        </th>
        <th scope="col" role="button" @click.prevent="updateOrder('date')">
          Datum
          <template v-if="orderBy === 'date'">
            <order-direction-indicator type="numeric" :asc="orderAsc" />
          </template>
        </th>
        <th scope="col">Aktion</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="order._id" v-for="order in orders">
        <th scope="row">{{ order.customer.name }}</th>
        <td><type-display :type="order.type" /></td>
        <td><status-display :status="order.status" /></td>
        <td><datetime-display :datetime="order.date" /></td>
        <td>
          <a class="btn" :href="`/${order._id}`">
            <i class="bi bi-pencil fs-4"></i>
          </a>
          <router-link
            :to="{ name: 'order.print', params: { id: order._id } }"
            class="btn"
            ><i class="bi bi-printer fs-4"></i
          ></router-link>
          <button
            type="button"
            class="btn"
            @click="deleteOrderItem = order"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            <i class="bi bi-trash fs-4"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex align-items-center justify-content-center">
    <button
      @click.prevent="previousPage"
      :disabled="!hasPrevious"
      class="m-1 btn btn-secondary"
    >
      Vorherige
    </button>
    <button
      @click.prevent="nextPage"
      :disabled="!hasNext"
      class="m-1 btn btn-secondary"
    >
      Nächste
    </button>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="confirmDeleteModal"
    ref="confirmDeleteModal"
    tabindex="-1"
    aria-labelledby="confirmDeleteLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmDeleteLabel">
            Löschen bestätigen
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Sind Sie sicher, dass dieser Auftrag gelöscht werden soll?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Abbrechen
          </button>
          <button type="button" class="btn btn-danger" @click="deleteOrder">
            Löschen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import TypeDisplay from "../components/TypeDisplay.vue";
import StatusDisplay from "../components/StatusDisplay.vue";
import DatetimeDisplay from "../components/DatetimeDisplay.vue";
import useOrderApi from "../api/orders";
import useResultQuerying from "../composition/ResultQuerying";
import OrderDirectionIndicator from "../components/OrderDirectionIndicator.vue";
import moment from "moment";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  components: {
    TypeDisplay,
    StatusDisplay,
    DatetimeDisplay,
    OrderDirectionIndicator,
    Datepicker,
  },
  setup() {
    const orders = ref([]);
    const deleteOrderItem = ref();
    const { index, remove } = useOrderApi();

    const { options, orderBy, orderAsc, setOrder, before, after, limit, skip, status } =
      useResultQuerying();

    const hasPrevious = computed(() => skip.value != 0);
    const hasNext = computed(() => orders.value.length >= limit.value);
    function nextPage() {
      skip.value += limit.value;
      loadOrder();
    }

    function previousPage() {
      skip.value -= limit.value;
      loadOrder();
    }

    function updateOrder(column) {
      if (orderBy.value === column) {
        setOrder(column, !orderAsc.value);
      } else {
        setOrder(column, true);
      }
      loadOrder();
    }

    function loadOrder() {
      return index(options).then((response) => {
        orders.value = response.data;
      });
    }

    function deleteOrder() {
      remove(deleteOrderItem.value._id)
        .then(loadOrder)
        .then(() => {
          Modal.getInstance(
            document.getElementById("confirmDeleteModal")
          ).hide();
        });
    }

    function selectCurrentMonth() {
      var start = moment().startOf("month").toDate();
      var end = moment().endOf("month").toDate();
      after.value = start;
      before.value = end;
      loadOrder();
    }

    onMounted(loadOrder);

    return {
      orders,
      deleteOrder,
      deleteOrderItem,
      updateOrder,
      options,
      orderBy,
      orderAsc,
      before,
      after,
      loadOrder,
      limit,
      hasNext,
      hasPrevious,
      nextPage,
      previousPage,
      selectCurrentMonth,
      status
    };
  },
};
</script>
