<template>
  <div class="card">
    <h5 class="card-header text-center py-3">Anmelden</h5>
    <div class="card-body">
      <form @submit.prevent.stop="login">
        <div class="mb-3">
          <label for="usernameInput" class="form-label">Username</label>
          <input
            type="text"
            id="usernameInput"
            class="form-control"
            v-model="form.username"
          />
        </div>
        <div class="mb-3">
          <label for="passwortInput" class="form-label">Passwort</label>
          <input
            type="password"
            id="passwortInput"
            class="form-control"
            v-model="form.password"
          />
        </div>
        <div class="mb-3">
          <input type="submit" class="btn btn-primary" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const { dispatch } = useStore()
    const form = reactive({
      username: null,
      password: null,
    })

    const login = (e) => {
      e.preventDefault()
      e.stopPropagation()
      dispatch('login', form).then(() => router.push('/'))
    }

    return {
      form,
      login,
    }
  },
}
</script>
