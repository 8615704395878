<template>
  <form @submit.prevent="submit" class="needs-validation">
    <div class="card card-body">
      <select-customer v-model:customer="form.customer" v-if="form.customer" />
    </div>
    <order-items v-model:formItems="form.items" :articles="articles" />
    <div class="card card-body my-3">
      <div class="row">
        <div class="mb-3 col-12">
          <label for="grave-form-color-input" class="form-label">Schleifenfarbe</label>
          <input type="text" class="form-control" id="grave-form-color-input" placeholder="Rot" v-model="form.color"
            required />
        </div>
      </div>
      <div class="mb-3">
        <label for="grave-form-notes-input" class="form-label">Bemerkungen</label>
        <textarea v-model="form.notes" rows="5" class="form-control" id="grave-form-notes-input"
          placeholder="Bemerkungen"></textarea>
      </div>
      <div class="row">
        <div class="mb-3 col">
          <label for="grave-form-text-left-input" class="form-label">
            Schleifentext links
          </label>
          <text-element-selector v-model="form.textLeft" class="mb-3" />
        </div>
        <div class="mb-3 col">
          <label for="grave-form-text-right-input" class="form-label">Schleifentext rechts</label>
          <text-element-selector v-model="form.textRight" class="mb-3" />
        </div>
      </div>
    </div>
    <div class="card card-body">
      <div class="row">
        <div class="mb-3 col-6">
          <label for="grave-form-burial-input" class="form-label">Beisetzung</label>
          <input v-model="form.burial" type="text" class="form-control" id="grave-form-burial-input"
            placeholder="Beisetzung" />
        </div>
        <div class="mb-3 col-6">
          <label for="grave-form-undertaker-input" class="form-label">Bestatter</label>
          <input v-model="form.undertaker" type="text" class="form-control" id="grave-form-undertaker-input"
            placeholder="Bestatter" />
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-6">
          <label for="grave-form-burial-location-input" class="form-label">Ort der Beisetzung</label>
          <input v-model="form.burialLocation" type="text" class="form-control" id="grave-form-burial-location-input"
            placeholder="Ort der Beisetzung" />
        </div>
        <div class="mb-3 col-6">
          <label for="grave-form-image-input" class="form-label">Bild-Nr.</label>
          <input v-model="form.imageNo" type="text" class="form-control" id="grave-form-image-input"
            placeholder="Bild-Nr." />
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-6 d-flex flex-col justify-content-around align-items-end">
          <div class="form-check">
            <input v-model="form.delivery" :value="true" class="form-check-input" type="radio"
              id="bouquet-form-delivery-yes-radio" />
            <label class="form-check-label" for="bouquet-form-delivery-yes-radio">
              Zustellung
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.delivery" :value="false" class="form-check-input" type="radio"
              id="bouquet-form-delivery-no-radio" />
            <label class="form-check-label" for="bouquet-form-delivery-no-radio">
              Abholung
            </label>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="bouquet-form-day-of-week-input" class="form-label">Datum</label>
          <Datepicker v-model="form.date"  :minDate="new Date()" locale="de" cancelText="abbrechen" selectText="auswählen"
            format="dd.MM.yyyy HH:mm" />
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-6 d-flex flex-row justify-content-around align-items-end">
          <div class="form-check">
            <input v-model="form.payment" value="cash" class="form-check-input" type="radio"
              id="bouquet-payment-cash" />
            <label class="form-check-label" for="bouquet-payment-cash">
              Barzahlung
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.payment" value="pickup" class="form-check-input" type="radio"
              id="bouquet-payment-pickup" />
            <label class="form-check-label" for="bouquet-payment-pickup">
              Bereits Bezhalt
            </label>
          </div>
          <div class="form-check">
            <input v-model="form.payment" value="invoice" class="form-check-input" type="radio"
              id="bouquet-payment-invoice" />
            <label class="form-check-label" for="bouquet-payment-invoice">
              Rechnung
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card my-3">
      <div class="card-body">
        <label class="form-label">Unterschrift</label>
        <signature-input v-model="form.signature" />
      </div>
      <div class="card-footer d-flex align-items-center justify-content-between">
        Unterschrift
        <!--<button class="btn btn-sm btn-secondary">Zurücksetzen</button>-->
      </div>
    </div>
    <button class="btn btn-secondary" type="submit">Speichern</button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('open')">
      Offen
    </button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('done')">
      Bearbeitet
    </button>
    <button class="btn btn-primary ms-3" v-if="isChange" @click="setStatus('callback')">
      Rückruf
    </button>
    <button class="btn btn-success ms-3" v-if="isChange" @click="setStatusFinish">
      Abschluss
    </button>

  </form>
</template>

<script>
import { computed, reactive, watch, ref, onMounted } from 'vue'
import SelectCustomer from './SelectCustomer'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import { useStore } from 'vuex'
import SignatureInput from '../SignatureInput.vue'
import useOrdersApi from '../../api/orders'
import useArticlesApi from '../../api/articles'
import { useRouter } from 'vue-router'
import TextElementSelector from './partials/TextElementSelector.vue'
import OrderItems from './partials/OrderItems.vue';

export default {
  components: {
    SelectCustomer,
    Datepicker,
    SignatureInput,
    TextElementSelector,
    OrderItems
  },
  props: ['order', 'isChange'],
  emit: ['save'],
  setup(props, { emit }) {
    const vuex = useStore();
    const router = useRouter();
    const articles = ref([]);
    const { list: loadAll } = useArticlesApi();
     onMounted(() => {
      loadAll().then(res => (articles.value = res.data.articles));
    });
    
    const initialForm = {
      customer: {
        name: '',
        address: '',
        location: '',
        phone: '',
        email: '',
      },
      items: [],
      payment: 'cash',
      status: 'open',
      textLeft: '',
      textRight: '',
      burial: '',
      undertaker: '',
      color: 'Creme',
      burialLocation: '',
      imageNo: '',
      notes: '',
      value: null,
      signature: null,
      date: new Date(),
      delivery: false,
    }

    var form = null
    if (props.order) {
      form = reactive(props.order)
    } else {
      form = reactive({
        ...initialForm,
      })
    }

    watch(
      () => props.order,
      (value) => {
        Object.assign(form, value)
      }
    )

    const { store: storeOrder } = useOrdersApi()
    const { getters } = useStore()
    const staff = computed(() => getters.active)


    function submit() {
      storeOrder({ ...form, type: 'grave', staff: staff.value }).then(() => {
        Object.assign(form, initialForm)
        emit('save')
        vuex.dispatch('messages/flash', {
          text: 'Auftrag wurde erfolgreich gespeichert',
        });
        router.push({ name: 'overview' });
      })
    }

    function setStatus(status) {
      form.status = status;
      submit();
    }

    const setStatusFinish = (e) => {
      if (form.items.some(item => !item.final_price)) {
        e.preventDefault();
        alert("Bitte geben Sie einen finalen Preis ein.")
      } else {
        setStatus('finish');
      }
    }

    return {
      form,
      articles,
      submit,
      setStatus,
      setStatusFinish
    }
  },
}
</script>
