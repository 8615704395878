import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import App from './App.vue';
import UpsertOrder from './pages/UpsertOrder';
import Login from "./pages/Login";
import Print from "./pages/Print";
import Dashboard from './pages/Dashboard';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import auth from './middleware/auth';
import './style.css'

const routes = [
  { path: '/', name: 'overview', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/create', component: UpsertOrder, meta: { requiresAuth: true } },
  { path: '/:id', component: UpsertOrder, meta: { requiresAuth: true } },
  { path: '/login', component: Login, meta: { requiresAuth: false } },
  { path: '/print/:id', component: Print, name: 'order.print', props: true, meta: { requiresAuth: true } },
]

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(auth);



createApp(App).use(store).use(router).mount('#app')
