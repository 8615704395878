
<template>
    <i class="bi" :class="iconClass"></i>
</template>

<script>
import { computed } from '@vue/reactivity'
export default {
    props: {
        type: {
            required: false,
            type: String,
            default: 'other',
        },
        asc: {
            required: true,
            type: Boolean,
        },
    },
    setup(props) {
        const typeComponent = computed(() => {
            switch(props.type) {
                case 'text':
                    return '-alpha';
                case 'numeric':
                    return '-numeric';
                default:
                    return '';
            }
        });
        const directionComponent = computed(() => props.asc ? '-down' : '-up');

        const iconClass = computed(() => `bi-sort${typeComponent.value}${directionComponent.value}`);

        return {
            iconClass,
        };
    },
}
</script>