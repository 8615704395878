<template>
    <div class="mb-3 position-relative">
        <label for="bouquet-form-customer-input" class="form-label">
            Empfänger
        </label>
        <input
            :value="modelValue.name"
            @input="updateName"
            @click="showResultsIfPossible"
            @focus="showResultsIfPossible"
            @focusout="hideResults"
            type="text"
            class="form-control"
            id="bouquet-form-customer-input"
            placeholder="Vorname Name"
            required
        />
        <template v-if="showSuggestions">
            <div class="autocomplete-custom-content top-100 position-absolute start-0 end-0 bg-light" style="z-index: 999;">
                <ul class="list-group">
                    <template
                        :key="suggestion.id"
                        v-for="suggestion in suggestions"
                    >
                        <li
                            @mousedown.prevent
                            @click.prevent="selectCustomer(suggestion)"
                            role="button"
                            class="list-group-item list-group-item-action"
                        >
                            <strong>{{ suggestion.name1 }} {{ suggestion.name2 }}</strong>
                            {{ suggestion.strasse }} {{ suggestion.plz }} {{ suggestion.ort }}
                        </li>
                    </template>
                </ul>
            </div>
        </template>
    </div>
    <div class="row">
        <div class="mb-3 col-6">
            <label for="bouquet-form-address-input" class="form-label">
                Straße
            </label>
            <input
                :value="modelValue.address"
                @input="updateAddress"
                type="text"
                class="form-control"
                id="bouquet-form-address-input"
                placeholder="Straße Nr."
                required
            />
        </div>
        <div class="mb-3 col-6">
            <label for="bouquet-form-location-input" class="form-label">
                PLZ, Ort
            </label>
            <input
                :value="modelValue.location"
                @input="updateLocation"
                type="text"
                class="form-control"
                id="bouquet-form-location-input"
                placeholder="12345 Ort"
                required
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import useSearchApi from '../../../api/customer';
export default {
    props: {
        modelValue: {
            type: Object,
            required: false,
            default () {
                return {
                    name: '',
                    address: '',
                    location: '',
                };
            },
        },
    },
    setup (props, { emit })
    {
        const suggestions = ref([]);
        const showSuggestions = ref(false);
        const { search } = useSearchApi();

        function updateName (event)
        {
            emitChanges({
                ...props.modelValue,
                name: event.target.value,
            });
            updateSuggestions(event.target.value);
        }

        function updateAddress (event)
        {
            emitChanges({
                ...props.modelValue,
                address: event.target.value,
            });
        }

        function updateLocation (event)
        {
            emitChanges({
                ...props.modelValue,
                location: event.target.value,
            });
        }

        function emitChanges (value)
        {
            emit('update:modelValue', value);
        }


        function updateSuggestions (searchTerm)
        {
            if (searchTerm.length > 0)
            {
                search(searchTerm).then(response => {
                    suggestions.value = response.data;
                    showSuggestions.value = true;
                });
            }
            else
            {
                suggestions.value = [];
            }
        }

        function selectCustomer (customer)
        {
            showSuggestions.value = false;
            emitChanges({
                name: `${customer.name1} ${customer.name2}`,
                address: customer.strasse,
                location: `${customer.plz} ${customer.ort}`,
            });
        }

        function showResultsIfPossible ()
        {
            if (suggestions.value.length > 0)
            {
                showSuggestions.value = true;
            }
        }

        function hideResults ()
        {
            showSuggestions.value = false;
        }

        return {
            updateName,
            updateAddress,
            updateLocation,
            suggestions,
            selectCustomer,
            showSuggestions,
            showResultsIfPossible,
            hideResults,
        };
    },
}
</script>