import useApi from './index';

export default function () {
    const { client } = useApi();
    const resourcePath = '/order';
    //const baseUrl = `/${ resourcePath }`;


    function index(data = null) {
        return client.get(resourcePath, { params: data });
    }

    function store(data = null) {
        return client.post(resourcePath, data)
    }

    function show(id) {
        return client.get(`${resourcePath}/${id}`);
    }

    function update(id) {
        return client.put(`${resourcePath}/${id}`);
    }

    function remove(id) {
        return client.delete(`${resourcePath}/${id}`);
    }

    return {
        resourcePath,
        index,
        store,
        show,
        update,
        remove,
    };
}