import { reactive, computed } from 'vue';

export default function () {
    const options = reactive({
        orderBy: 'date',
        direction: 'asc',
        before: null,
        after: null,
        status : 'open',
        limit: 10,
        skip: 0,
    });

    const orderBy = computed(() => options.orderBy);
    const orderAsc = computed(() => options.direction !== 'desc');

    const before = computed({
        get: () => (options.before),
        set (value) {
            options.skip = 0;
            options.before = value;
        },
    });

    const after = computed({
        get: () => (options.after),
        set (value) {
            options.skip = 0;
            options.after = value;
        },
    });

    const status = computed({
        get: () => (options.status),
        set (value) {
            options.skip = 0;
            options.status = value;
        },
    });

    const limit = computed({
        get: () => (options.limit),
        set (value) {
            options.skip = 0;
            options.limit = value;
        },
    });

    const skip = computed({
        get: () => (options.skip),
        set (value) {
            options.skip = value;
        },
    });

    function setOrder (column, asc = true) {
        options.orderBy = column;
        options.skip = 0;
        options.direction = asc ? 'asc' : 'desc';
    }

    return {
        options,
        orderBy,
        orderAsc,
        setOrder,
        before,
        after,
        limit,
        skip,
        status
    };
}