<template>
    <span>{{ datetimeDisplay }}</span>
</template>

<script>
import { computed } from '@vue/reactivity';
import moment from 'moment';

export default {
    props: {
        datetime: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const datetimeDisplay = computed(() => moment(props.datetime).format("DD.MM.YYYY HH:mm"));

        return {
            datetimeDisplay,
        };
    },
}
</script>