import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import currentStaffModule from './modules/currentStaff';
import authModule from './modules/auth';
import flash from './modules/flash';

export default createStore({
    modules: {
        currentStaff: currentStaffModule,
        auth: authModule,
        messages: flash,
    },
    plugins: [
        createPersistedState({
            paths: ['currentStaff', 'auth'],
        }),
    ],
});