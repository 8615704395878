<template>
  <Layout>
    {{ $auth }}
    <router-view></router-view>
  </Layout>
</template>

<script>
import Layout from './layout'
export default {
  name: 'App',
  components: {
    Layout,
  },
}
</script>
