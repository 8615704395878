<template>
    <div class="container">
        <h1>Auftragsübersicht</h1>
        <template v-if="order">
            <div class="row">
                <div class="d-flex align-items-center justify-content-between text-muted mb-4">
                    <span>
                        <datetime-display :datetime="order.date" />
                    </span>
                    <span>
                        {{ order.type == 'bouquet' ? 'Strauß' : 'Trauerfeier' }}
                    </span>
                    <span>
                        Personal: {{ order.staff }}
                    </span>
                </div>
                <div class="col row">
                    <h6>Auftraggeber</h6>
                    <div class="col">
                        <address>
                            <strong>{{ order.customer.name }}</strong><br/>
                            {{ order.customer.address }}<br/>
                            {{ order.customer.location }}
                        </address>
                    </div>
                    <div class="col">
                        <template v-if="order.customer.email">
                            <span><i class="bi bi-envelope mx-1"></i>{{ order.customer.email }}</span><br/>
                        </template>
                        <template v-if="order.customer.phone">
                            <span><i class="bi bi-telephone mx-1"></i>{{ order.customer.phone }}</span>
                        </template>
                    </div>
                </div>
                <template v-if="order.type == 'bouquet'">
                    <div class="col row">
                        <h6>Empfänger</h6>
                        <div class="col">
                            <address>
                                <strong>{{ order.recipient.name }}</strong><br/>
                                {{ order.recipient.address }}<br/>
                                {{ order.recipient.location }}
                            </address>
                            <span>
                                <strong>{{ order.delivery ? 'Lieferung':'Abholung' }}</strong>
                            </span>
                        </div>
                        <div class="col">
                            <template v-if="order.recipient.email">
                                <span><i class="bi bi-envelope mx-1"></i>{{ order.customer.email }}</span><br/>
                            </template>
                            <template v-if="order.recipient.phone">
                                <span><i class="bi bi-telephone mx-1"></i>{{ order.customer.phone }}</span>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="order.type == 'grave'">
                    <div class="col row">
                        <h6>Beisetzung</h6>
                        <div class="col">
                            <address>
                                <strong>{{ order.undertaker }}</strong><br/>
                                {{ order.burialLocation }}<br/>
                                {{ order.burial }}
                            </address>
                            <span>
                                <strong>{{ order.delivery ? 'Lieferung':'Abholung' }}</strong>
                            </span>
                            <div class="mt-2">
                                <strong>Schleifentext Links:</strong>
                                {{ order.textLeft }}
                            </div>
                            <div class="mt-1">
                                <strong>Schleifentext Rechts:</strong>
                                {{ order.textRight }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="order.notes">
                    <div class="d-flex align-items-center justify-content-between text-muted my-2">
                        <span>
                            {{ order.notes }}
                        </span>
                    </div>
                </template>
                <hr>
                <template v-if="order.items.length">
                    <table>
                        <thead>
                            <tr>
                                <th>Artikel</th>
                                <th>Bemerkung</th>
                                <th>Skizze</th>
                                <th>Wert</th>
                            </tr>
                        </thead>
                        <template :key="item._id" v-for="item in order.items">
                            <tbody>
                                <tr>
                                    <td>{{ item.article.KURZNAME }}</td>
                                    <td>{{ item.notes }}</td>
                                    <td>
                                        <div
                                            style="width: 100%; height: 100px; background-size: contain; background-repeat: no-repeat"
                                            :style="{ 'background-image': `url(${item.sketch})` }"
                                        ></div>
                                    </td>
                                    <td>{{ item.value }}</td>
                                </tr>
                            </tbody>
                        </template>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Gesamtwert</th>
                                <th>{{ Number(order.value) }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
import useOrderApi from '../api/orders';
import DatetimeDisplay from '../components/DatetimeDisplay.vue';
export default {
    components: { DatetimeDisplay },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const order = ref(null);

        const { show } = useOrderApi();

        function fetchOrder()
        {
            show(props.id).then(response => order.value = response.data).then(() => window.print());
        }

        onMounted(fetchOrder);
        watch(() => props.id, fetchOrder);

        return {
            order,
        };
    },
}
</script>