<template>
  <div class="mb-3 position-relative">
    <label for="bouquet-form-customer-input" class="form-label"
      >Auftraggeber</label
    >
    <input
      :value="customer.name"
      @input="inputChange"
      type="text"
      class="form-control"
      id="bouquet-form-customer-input"
      autocomplete="off"
      placeholder="Vorname Name"
      required
    />
    <div
      class="autocomplete-custom-content top-100 position-absolute start-0 end-0 bg-light"
      v-if="suggestions.length"
    >
      <ul class="list-group">
        <li
          class="list-group-item"
          :key="suggestion.id"
          v-for="suggestion in suggestions"
          @click="selectCustomer(suggestion)"
        >
          {{ suggestion.name1 }} {{ suggestion.name2 }}
          {{ suggestion.strasse }} {{ suggestion.plz }} {{ suggestion.ort }}
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-6">
      <label for="bouquet-form-address-input" class="form-label">Straße</label>
      <input
        :value="customer.address"
        @input="
          $emit('update:customer', {
            ...customer,
            address: $event.target.value,
          })
        "
        type="text"
        class="form-control"
        id="bouquet-form-address-input"
        placeholder="Straße Nr."
        required
      />
    </div>
    <div class="mb-3 col-6">
      <label for="bouquet-form-location-input" class="form-label"
        >PLZ, Ort</label
      >
      <input
        :value="customer.location"
        @input="
          $emit('update:customer', {
            ...customer,
            location: $event.target.value,
          })
        "
        type="text"
        class="form-control"
        id="bouquet-form-location-input"
        placeholder="12345 Ort"
        required
      />
    </div>
  </div>
  <div class="row">
    <div class="mb-3 col-6">
      <label for="bouquet-form-phone-input" class="form-label">Telefon</label>
      <input
        :value="customer.phone"
        @input="
          $emit('update:customer', { ...customer, phone: $event.target.value })
        "
        type="text"
        class="form-control"
        id="bouquet-form-phone-input"
        placeholder="0123 456789"
      />
    </div>
    <div class="mb-3 col-6">
      <label for="bouquet-form-email-input" class="form-label"
        >Email Adresse</label
      >
      <input
        :value="customer.email"
        @input="
          $emit('update:customer', { ...customer, email: $event.target.value })
        "
        type="email"
        class="form-control"
        id="bouquet-form-email-input"
        placeholder="vorname.name@example.com"
      />
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import useSearchApi from '../../api/customer'
import _ from 'lodash';

export default {
  props: {
    customer: Object,
  },
  emits: ['update:customer'],
  setup(props, { emit }) {
    const suggestions = ref([])
    const { search } = useSearchApi();

    const selectCustomer = function(suggestion) {
      suggestion = _.mapValues(suggestion, v => !v ? '' : v)
      console.log(suggestion);
      const customer = {
        name: `${suggestion.name1} ${suggestion.name2}`,
        address: `${suggestion.strasse}`,
        location: `${suggestion.plz} ${suggestion.ort}`,
        phone: `${suggestion.tele1}`,
        email: `${suggestion.email}`,
      }
      emit('update:customer', customer)
      suggestions.value = []
    }

    const inputChange = (e) => {
      emit('update:customer', { ...props.customer, name: e.target.value })
      var searchterm = e.target.value
      if (!searchterm.length) {
        suggestions.value = []
      } else {
        search(searchterm)
          .then((res) => (suggestions.value = res.data))
      }
    }
    return {
      suggestions,
      selectCustomer,
      inputChange,
    }
  },
}
</script>
