export default {
    state: () => ({
        active: null,
    }),
    getters: {
        active (state) {
            return state.active;
        },
        hasActive (state) {
            return state.active !== null;
        },
    },
    mutations: {
        setActive (state, staff) {
            state.active = staff;
        },
    },
}