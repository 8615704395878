import useApi from './index';

export default function() {
    const { client } = useApi();
    const resourcePath = '/staff';
   

    function index (data = null) {
        return client.get(resourcePath, { params: data });
    }

    function store () {
        return client.post(resourcePath)
    }

    function show (id) {
        return client.get(`${ resourcePath }/${id}`);
    }

    function update (id) {
        return client.put(`${ resourcePath }/${id}`);
    }

    function remove (id) {
        return client.delete(`${ resourcePath }/${id}`);
    }

    return {
        resourcePath,
        index,
        store,
        show,
        update,
        remove,
    };
}